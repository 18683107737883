import Navbar from './navbar';

function Layout({ tabs, children }) {
    return (
        <div>
            <Navbar tabs={tabs} />
            <div style={{
                paddingInline: "1rem"
            }}>
                {children}
            </div>
        </div>
    );
}

export default Layout;
