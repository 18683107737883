import './styles/modal.scss';
import { ReactComponent as CloseSVG } from '../assets/icons/x.svg';

function Modal({ open, title, onClose, children, className, onCancel }) {

    return <div className={'modal ' + (className || "")} data-state={open ? "open" : "closed"}>
        <div className='content'>
            <div className='header'>
                <div className='title'>{title}</div>
                <div><button onClick={onClose}><CloseSVG /></button></div>
            </div>
            <div>{children}</div>
        </div>
    </div>
}

export default Modal;
