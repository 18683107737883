import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import MenusEdit from './pages/menus/edit/edit';
import MenusQRCode from './pages/menus/qrcode/qrcode';
import MenusDesign from './pages/menus/design/design';
import Protected from './components/protected';
import '@fontsource-variable/noto-sans-jp';
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import Home from './pages/home';
import Root from './pages/root';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/menus/edit",
    element: <Protected><MenusEdit /></Protected>,
  },
  {
    path: "/menus/qrcode",
    element: <MenusQRCode />,
  },
  {
    path: "/menus/design",
    element: <MenusDesign />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <KindeProvider
      clientId={process.env.REACT_APP_KINDE_CLIENT_ID}
      domain={process.env.REACT_APP_KINDE_DOMAIN}
      logoutUri={process.env.REACT_APP_KINDE_LOGOUT_URL}
      redirectUri={process.env.REACT_APP_KINDE_REDIRECT_URL}
    >
      <RouterProvider router={router} />
    </KindeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
