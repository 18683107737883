import LayoutMenus from '../../../components/layoutMenus';
import './style.scss';

function MenusDesign() {
    return (
        <LayoutMenus>
            <div> Design</div>
        </LayoutMenus>
    );
}

export default MenusDesign;
