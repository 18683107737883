import Layout from "./layout";

function LayoutMenus({ children }) {
    return (
        <Layout tabs={[
            {
                path: "/menus/edit",
                label: "Menus"
            },
            {
                path: "/menus/qrcode",
                label: "QR Code"
            },
            {
                path: "/menus/design",
                label: "Design"
            }
        ]}
            children={children} />
    );
}

export default LayoutMenus;
