import { Link } from 'react-router-dom';
import './styles/navbar.scss'
import { useLocation } from 'react-router-dom';
import Modal from './modal';
import { useState } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

const allModules = [
    {
        title: "Menus",
        description: "Create, manage and design your menus. Download QR codes. And more.",
        path: "/menus/edit"
    },
    {
        title: "Accounts",
        description: "Manage your personal and business information.",
        path: "/accounts"
    },
    {
        title: "Support",
        description: "Get help from the team.",
        path: "/support"
    }
]


function ModuleCard({ path, title, description }) {
    return <div className='module-card'>
        <Link to={path}><div>
            <div className="title">{title}</div>
            <div>{description}</div>
        </div>
        </Link>
    </div>
}


function Navbar({ tabs }) {
    const [showModal, setShowModal] = useState(false)
    let location = useLocation();
    const { isLoading, logout } = useKindeAuth();


    // if (isLoading) return <>Loading...</>
    if (isLoading) return <></>

    return (
        <div id="top-nav">
            <nav>
                {tabs.map(tab => <Link key={`top-nav-${tab.path}`} to={tab.path} data-state={location.pathname === tab.path ? "on" : "off"}>{tab.label}</Link>)}
            </nav>
            <button id="more-options" className='meta' onClick={() => setShowModal(true)}>More options</button>
            <Modal open={showModal} title={"All modules"} onClose={() => setShowModal(false)}>
                <>
                    <div className='module-cards'>
                        {allModules.map(module => <ModuleCard key={`top-nav-module-${module.path}`} title={module.title} description={module.description} path={module.path} />)}
                    </div>
                    <br />
                    <br />
                    <button className='fullwidth centered' onClick={logout}>Logout</button>
                    <br />
                    <div id='version'>
                        App version 2.3.0
                    </div>
                </>
            </Modal>
        </div>
    );
}

export default Navbar;
