import React, { useEffect, useState } from 'react';
import './styles/select.scss'
// import { ReactComponent as TriangleSVG } from '../assets/icons/triangle.svg';


/*
option = {
    key: string,
    label: string,
    value: HTMLElement,
*/

export function DefaultOption({ label, onClick }) {
    return <div className="option">
        {label}
    </div>
}

export function ButtonOption({ label, onClick }) {
    return <button>{label}</button>
}

// TODO: schema for value, options, placeholder
export function Select({ value, options, placeholder, onSelect }) {
    const [selectedOption, setSelectedOption] = useState(value)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setSelectedOption(value)
    }, [value])

    useEffect(() => {
        if (onSelect && (value?.key !== selectedOption?.key)) {
            onSelect(selectedOption)
        }
    }, [selectedOption, value, onSelect])

    return <div className="custom-select" data-state={open ? "open" : "closed"} tabIndex={0} onBlur={() => setOpen(false)} >
        <div className='header' onClick={() => setOpen(!open)}>
            <div className='label'>{(selectedOption?.label) || placeholder}</div>
            <div style={{ display: "flex" }}><span style={{
                color: "#006aff",
                fontSize: "10px"
            }}>	&#9660;</span></div>
        </div>
        {options.length && <div className="options">
            {options.map((option, i) => {
                var child;

                if (option.type === "button") child = <ButtonOption label={option.label} onClick={option.onClick} />
                if (!option.type) child = <DefaultOption label={option.label} onClick={option.onClick} />
                return <div key={option.key} className={"option-wrapper" + (selectedOption?.key === option.key ? " selected" : "")} onClick={() => {
                    if (option.onClick) {
                        option.onClick() // TODO: optimal?
                    } else {
                        setSelectedOption(option)
                        setOpen(false)
                    }
                }}
                >
                    {child}
                </div>
            })}
        </div>}
    </div>
}