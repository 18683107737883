const menu = [
  {
    "id": "0.8863522888257107",
    "name": "BREAKFAST",
    "description": "",
    "footnote": "",
    "items": [
      {
        "id": "0.6126087799883917",
        "name": "Full English Breakfast",
        "description": "Sunnyside eggs, sausage, bacon, hash browns, baked beans, sautéed tomatoes, mushrooms, and toast w/ a cup of tea or black coffee",
        "labels": [],
        "price": [{ "prefix": "", "amount": "90.00", "suffix": " / portion" }],
        "options": [],
        "notes": "",
        "images": ["WhatsApp Image 2021-12-14 at 11.22.37 AM.jpeg"]
      },
      {
        "id": "0.4305663528364789",
        "name": "Omelets with 2 toast",
        "description": "4 eggs - Meat lovers (ham/sausage/bacon) Veggie Lovers (mushrooms/bell peppers/onions/spinach)",
        "labels": [],
        "price": [
          {
            "prefix": "Meat lovers",
            "amount": "60.00",
            "suffix": " / portion"
          },
          {
            "prefix": "Veggie lovers",
            "amount": "55.00",
            "suffix": " / portion"
          }
        ],
        "options": [],
        "notes": "",
        "images": ["WhatsApp Image 2021-12-14 at 11.17.10 AM.jpeg"]
      },
      {
        "id": "0.15063958765560814",
        "name": "Nuru Stack",
        "description": "Fluffy Pancakes with scrambled eggs , peppers, onions and mushroom",
        "labels": [],
        "price": [{ "prefix": "", "amount": "70.00", "suffix": " / portion" }],
        "options": [],
        "notes": "",
        "images": []
      },
      {
        "id": "0.6340915864770711",
        "name": "Banana Crepes",
        "description": "Banana crepes with Nutella  spread",
        "labels": [],
        "price": [{ "prefix": "", "amount": "45.00", "suffix": " / portion" }],
        "options": [],
        "notes": "",
        "images": ["WhatsApp Image 2021-12-14 at 12.15.34 PM (1).jpeg"]
      },
      {
        "id": "0.0722866468815484",
        "name": "Toast",
        "description": "Toast with two house bread recipe",
        "labels": [],
        "price": [{ "prefix": "", "amount": "15.00", "suffix": "" }],
        "options": [],
        "notes": "",
        "images": ["WhatsApp Image 2022-01-08 at 5.48.03 PM.jpeg"]
      },
      {
        "id": "0.7121954935901218",
        "name": "",
        "description": "",
        "labels": [],
        "price": [],
        "options": [],
        "notes": ""
      }
    ],
    "subsections": [],
    "images": ["WhatsApp Image 2021-12-14 at 10.51.29 AM.jpeg"]
  }
]

export default menu;