// import React from 'react';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";
import './home.scss'

function Home() {
    const { isLoading, isAuthenticated, login, register } = useKindeAuth();


    // if (isLoading) return <>Loading...</>;
    if (isLoading) return <></> // TODO

    if (isAuthenticated) return <Navigate to="/menus/edit" replace={true} />

    return <div id="home-page">
        <div className="content">
            <div className="centered">
                <h1>resto<span style={{ color: "purple" }}>bueno</span></h1>
                <div>Solutions for restaurants and hospitality professionals.</div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="fullwidth centered">
                <button className="outline fullwidth" onClick={login}>Log in</button>
            </div>
            <div className="fullwidth centered">
                <br />
                <br />
                <br />
                <div>You do not have an account?</div>
                <br />
                <button className="fullwidth" onClick={register}>Create account</button>
            </div>
        </div>
    </div>
}

export default Home;