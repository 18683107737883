import { Children, useEffect } from 'react';
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from 'react-router-dom';

// TODO: add global context / state

function Protected({ children }) {
    const { isLoading, isAuthenticated, user } = useKindeAuth();


    useEffect(() => {
        console.log("User", user)
    }, [user])

    // if (isLoading) return <>Loading...</>;
    if (isLoading) return <></>;
    if (!isAuthenticated) return <Navigate to="/home" replace={true} />


    return <>
        {
            Children.map(children, child => child)
        }
    </>;
}

export default Protected;
