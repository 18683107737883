import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { Navigate } from "react-router-dom";

function Root() {
    const { isLoading, isAuthenticated } = useKindeAuth();

    // if (isLoading) return <>Loading...</> // TODO
    if (isLoading) return <></> // TODO

    if (isAuthenticated) return <Navigate to="/menus/edit" replace={true} />
    else return <Navigate to="/home" replace={true} />
}

export default Root;