import { useMemo, useState } from 'react';
import LayoutMenus from '../../../components/layoutMenus';
// import Modal from '../../../components/modal';
import { Select } from '../../../components/select';
import './style.scss';
import menuJson from '../../../tmp/menu'
import Modal from '../../../components/modal';
import { ReactComponent as DeleteSVG } from '../../../assets/icons/trash.svg';

// TODO: Rename item.prices to item.prices

// function emptySection() {
//     return {
//         id: Math.random(),
//         name: '',
//         description: '',
//         hidden: false,
//         items: [],
//         subsections: [],
//         footnote: ''
//     }
// }

// function emptySubsection() {
//     return {
//         id: Math.random(),
//         name: '',
//         description: '',
//         hidden: false,
//         items: [],
//         footnote: ''
//     }
// }

function emptyItem() {
    return {
        id: Math.random(),
        name: '',
        description: '',
        footnote: '',
        tags: [],
        options: [],
        prices: [],
        images: [],
        customizations: [],
        hidden: false,
        chefSelection: false
    }
}

// function newSectionModal({ onClose, onCancel }) {
//     return <Modal
//         className="section-modal"
//         title={"New section"}
//         open={true}
//         onCancel={onCancel}
//         onClose={() => {
//             onClose({ conten: {} })
//         }}>
//         <div>Hello</div>
//     </Modal>
// }

function Item({ item, deleteItem }) {
    const [openEditingModal, setOpenEditingModal] = useState(false)
    const [, setForceRefresh] = useState(Math.random())

    return <div className='item-row'>
        <div className='item'>
            <div className='preview' onClick={() => setOpenEditingModal(true)}>
                <div className="header">
                    {item.name ? <b>{item.name}</b> : <i style={{ color: "gray" }}>No name</i>}
                    {" "}
                    {item.description || ""}
                </div>
                <div className='flex-col' style={{ marginTop: "0.25rem" }}>
                    {item.cs && <div className='chef-selection'>This item is will be shown as recommended by the Chef.</div>}
                    {item.hide && <div className='hidden'>This item is hidden and will not appear on your menu.</div>}
                </div>
            </div>
            <div className="actions-list"> {/** TODO: onClick */}
                <div>
                    <button onClick={() => setOpenEditingModal(true)}>edit</button>
                </div>
                <div>
                    <button onClick={() => {
                        item.hide = !item.hide
                        setForceRefresh(Math.random())
                    }}>hide</button>
                </div>
                {/* <div>
                    <button
                        onClick={() => {
                            item.cs = !item.cs
                            setForceRefresh(Math.random())
                        }} className='nowrap'>chef's selection</button>
                </div> */}
                <div className='danger'>
                    <button
                        className='danger'
                        onClick={deleteItem}
                    >delete</button>
                </div>
            </div>
        </div>
        <div className="actions"> {/** TODO: onClick */}
            <button>&#8593;</button>
            <button>&#8595;</button>
        </div>
        <Modal className="item-edit-modal" title={"Editing item"} open={openEditingModal} onClose={() => {
            setOpenEditingModal(false)
        }}>
            <div>
                <div className="menu">
                    <div className='flex-col'>
                        {item.cs && <div className='chef-selection'>This item is will be shown as recommended by the Chef.</div>}
                        {item.hide && <div className='hidden'>This item is hidden and will not appear on your menu.</div>}
                    </div>
                    <div className="actions-list">
                        <div>
                            <button onClick={() => {
                                item.hide = !item.hide
                                setForceRefresh(Math.random())
                            }}>hide</button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    item.cs = !item.cs
                                    setForceRefresh(Math.random())
                                }}
                                className='nowrap'>chef's selection</button>
                        </div>
                        <div className='danger'>
                            <button
                                onClick={deleteItem}
                                className='danger'>delete</button>
                        </div>
                    </div>
                </div>
                <div className="fields">
                    <div>
                        <input type="text" defaultValue={item.name || ""} placeholder='Item name' onChange={(e) => item.name = e.target.value} />
                    </div>
                    <div>
                        <textarea rows={2} defaultValue={item.description || ""} placeholder='Description' onChange={(e) => item.description = e.target.value} />
                    </div>
                    <div className="prices">
                        {item.prices?.map((price, i) => {
                            return <div className='price' key={`item-${item.id}-price-${i}-${item.prices.length}`}>
                                <div className="currency">
                                    <input placeholder='Currency' defaultValue={price.currency} onChange={(e) => price.currency = e.target.value} /> {/** TODO: use select */}
                                </div>
                                <div className='amount'>
                                    <input placeholder='Value' defaultValue={price.amount} onChange={(e) => price.amount = e.target.value} /> {/** TODO: force float positive */}
                                    <div style={{ textAlign: "right" }}>
                                        <button onClick={() => alert("TODO")}>format</button>
                                    </div>
                                </div>
                                <div className='description'>
                                    <input placeholder='Description' defaultValue={price.description} onChange={(e) => price.description = e.target.value} /> {/** TODO:  */}
                                    <div style={{ textAlign: "right" }}>
                                        <button onClick={() => alert("TODO")}>format</button>
                                    </div>
                                </div>
                                <div className='trash'>
                                    <button className='danger' onClick={() => {
                                        item.prices = [...item.prices.slice(0, i), ...item.prices.slice(i + 1, item.prices.length)]
                                        setForceRefresh(Math.random())
                                    }}><DeleteSVG /></button>
                                </div>
                            </div>
                        })}
                        <div>
                            <button onClick={() => {
                                if (item.prices && item.prices.length > 0) {
                                    item.prices = [...item.prices, { currency: '', amount: 0, description: '' }] // TODO: Add formatting
                                } else {
                                    item.prices = [{ currency: '', amount: 0, description: '' }]
                                }
                                setForceRefresh(Math.random())

                            }}>+ add price</button>
                        </div>
                    </div>
                    <div className="add-ons">
                        {item.options?.map((option, i) => { // TODO: rename to add-on
                            return <div className='add-on' key={`item-${item.id}-option-${i}-${item.options.length}`}>
                                <div className='amount'>
                                    <input placeholder='Value' defaultValue={option.amount} onChange={(e) => option.amount = e.target.value} /> {/** TODO: force float positive */}
                                </div>
                                <div className='description'>
                                    <input placeholder='Description' defaultValue={option.description} onChange={(e) => option.description = e.target.value} /> {/** TODO:  */}
                                </div>
                                <div className='trash'>
                                    <button className='danger' onClick={() => {
                                        item.options = [...item.options.slice(0, i), ...item.options.slice(i + 1, item.options.length)]
                                        setForceRefresh(Math.random())
                                    }}><DeleteSVG /></button>
                                </div>
                            </div>
                        })}
                        <div>
                            <button onClick={() => {
                                if (item.options && item.options.length > 0) {
                                    item.options = [...item.options, { currency: '', amount: 0, description: '' }] // TODO: Add formatting
                                } else {
                                    item.options = [{ currency: '', amount: 0, description: '' }]
                                }
                                setForceRefresh(Math.random())

                            }}>+ add add-on</button>
                        </div>
                    </div>
                    <div className="customization-options"> {/** User will select among */}
                        {item.customizations?.map((customization, i) => { // TODO: rename to add-on
                            return <div className='add-on' key={`item-${item.id}-customization-${i}-${item.customizations.length}`}>
                                <div className='description'> {/** TODO: use select */}
                                    <input placeholder='Description' defaultValue={customization.description} onChange={(e) => customization.description = e.target.value} /> {/** TODO:  */}
                                </div>
                                <div className='trash'>
                                    <button className='danger' onClick={() => {
                                        item.customizations = [...item.customizations.slice(0, i), ...item.customizations.slice(i + 1, item.customizations.length)]
                                        setForceRefresh(Math.random())
                                    }}><DeleteSVG /></button>
                                </div>
                            </div>
                        })}
                        <div>
                            <button onClick={() => {
                                if (item.customizations && item.customizations.length > 0) {
                                    item.customizations = [...item.customizations, { currency: '', amount: 0, description: '' }] // TODO: Add formatting
                                } else {
                                    item.customizations = [{ currency: '', amount: 0, description: '' }]
                                }
                                setForceRefresh(Math.random())

                            }}>+ add customization option</button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
}

function addItem(menuData, selectedSection, selectedSubsection) {
    if (selectedSection === null) return

    if (selectedSubsection === null) {
        menuData[selectedSection.index].items.push(emptyItem())
    } else {
        menuData[selectedSection.index].subsections[selectedSubsection.index].items.push(emptyItem())
    }
}

function deleteItem(menuData, itemIndex, selectedSection, selectedSubsection) { // TODO: can it be put outside?
    if (selectedSection === null) return

    if (selectedSubsection === null) {
        menuData[selectedSection.index].items.splice(itemIndex, 1)
    } else {
        menuData[selectedSection.index].subsections[selectedSubsection.index].items.splice(itemIndex, 1)
    }
}

function MenusEdit() {
    const [selectedMenu, setSelectedMenu] = useState({ label: "Menu 1", key: 1 })
    const [selectedLanguage, setSelectedLanguage] = useState({ label: "en", key: 1 })
    const [selectedSection, setSelectedSection] = useState(null)
    const [selectedSubsection, setSelectedSubsection] = useState(null)
    const [menuOptionsOpen, setMenuOptionsOpen] = useState(false)
    const [, setShowNewSectionModal] = useState(false)
    const [, setForceRefresh] = useState(Math.random())

    var menuData = useMemo(() => {
        if (!selectedMenu || !selectedLanguage) return null
        return menuJson
    }, [selectedMenu, selectedLanguage])

    var items = (selectedSection === null ? [] : ( // TODO: Use memo ?
        selectedSubsection === null ? menuData[selectedSection.index]["items"] : menuData[selectedSection.index]["subsections"][selectedSubsection.index]["items"]
    ))

    function _deleteItem(itemIndex) { // TODO: can it be put outside?
        deleteItem(menuData, itemIndex, selectedSection, selectedSubsection)
        setForceRefresh(Math.random())
    }

    function _addItem() {
        addItem(menuData, selectedSection, selectedSubsection)
        setForceRefresh(Math.random())
    }

    return (
        <LayoutMenus>
            <div id="menu-edit-page">
                <div className="header">
                    <div className='menu-selection'>
                        <div>
                            <Select placeholder={"Please choose a section"}
                                onSelect={setSelectedMenu}
                                value={selectedMenu}
                                options={[{ label: "Menu 1", key: 1 }, { label: "Menu 2", key: 2 }, { label: "Menu 3", key: 3 }]}
                            />
                        </div>
                        <div>
                            <Select placeholder={"Please choose a language"}
                                onSelect={setSelectedLanguage}
                                value={selectedLanguage}
                                options={[{ label: "en", key: 1 }, { label: "Language 2", key: 2 }, { label: "Language 3", key: 3 }]}
                            />
                        </div>
                        <div style={{ overflow: "visible" }}>
                            <button onClick={() => setMenuOptionsOpen(!menuOptionsOpen)} >options</button>
                        </div>
                    </div>
                    {menuOptionsOpen && <div style={{ marginTop: "-0.5rem", marginBottom: "0.5rem" }}>
                        <div className="actions-list">
                            <div>
                                <button onClick={() => { /** TODO */ }}>download</button>
                            </div>
                            <div>
                                <button onClick={() => { /** TODO */ }}>upload</button>
                            </div>
                            <div>
                                <button onClick={() => { /** TODO */ }} className='danger'>delete</button>
                            </div>
                        </div>
                    </div>}
                    <div className='section-selection'>
                        <div>
                            <Select placeholder={<span style={{ color: "red" }}>Please choose a section</span>}
                                onSelect={setSelectedSection}
                                value={selectedSection}
                                options={
                                    [
                                        ...menuData.map((section, i) => ({
                                            key: section.id,
                                            label: section.name || <i>insert name</i>,
                                            index: i
                                        })),
                                        {
                                            key: "add-section-button",
                                            label: "+ add new section",
                                            onClick: () => setShowNewSectionModal(true),
                                            type: "button"
                                        }
                                    ]
                                }
                            />
                        </div>
                        <div>
                            <Select placeholder={"Please choose a subsection"}
                                onSelect={setSelectedSubsection}
                                value={selectedSubsection}
                                options={[{ label: "Option 1", key: 1 }, { label: "Option 2", key: 2 }, { label: "Option 3", key: 3 }]}
                            />
                        </div>
                    </div>
                </div>
                {(menuData || (selectedSection !== null)) && <div className='editor'>
                    <div className="items">
                        {items.map((item, itemIndex) => <Item item={item} key={`item-${item.id}`} deleteItem={() => _deleteItem(itemIndex)} />)}
                    </div>
                    <div>
                        <button onClick={_addItem}>+ add item to section</button>
                    </div>
                </div>}
            </div>
        </LayoutMenus>
    );
}

export default MenusEdit;
