import LayoutMenus from '../../../components/layoutMenus';
import './style.scss';

function MenusQRCode() {
    return (
        <LayoutMenus>
            <div> QR Code</div>
        </LayoutMenus>
    );
}

export default MenusQRCode;
